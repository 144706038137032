@import 'scss/core/variables';
@import 'scss/core/mixins';

.epx-table {
    & .p-datatable-header {
        padding: 0 0 toRem(10) 0;
        border: none;
    }

    & .p-datatable-table {
        border-collapse: separate;
        border-spacing: 0 toRem(6) !important;
    }
    & .p-datatable-wrapper {
        scrollbar-gutter: stable;
        overflow: hidden !important;
        padding-right: 0.5rem;
        @include scrollContainerHover();
        @include scrollbars();
    }

    & .p-sortable-column.p-highlight {
        background-color: clr(primary, light);
        color: inherit;

        &:focus {
            background-color: clr(primary, light);
        }
    }
}

.epx-table.p-datatable {
    &.p-datatable-hoverable-rows {
        & .p-datatable-tbody tr {
            &:hover {
                cursor: pointer;
                transition: font-weight ease-out 0.1s;
                background: rgba(0, 0, 0, 0.04) !important;
            }
        }
    }
}

.epx-table .p-datatable-tbody {
    & tr {
        font-weight: $font-weight-regular;
        transition: font-weight ease-in 0.1s;
    }

    & tr > td {
        height: toRem(65);
        border-top: 1px solid clr(primary, light);
        border-bottom: 1px solid clr(primary, light);
        font-size: toRem(14);
        font-weight: inherit;
        font-family: $font-family-primary;
        color: clr(primary, main);
        line-height: 1.43;
        display: table-cell;
        vertical-align: inherit;
        padding: toRem(8) toRem(20);

        &:first-of-type {
            border-left: 1px solid clr(primary, light);
            border-top-left-radius: toRem(4);
            border-bottom-left-radius: toRem(4);
        }
        &:last-of-type {
            border-right: 1px solid clr(primary, light);
            border-top-right-radius: toRem(4);
            border-bottom-right-radius: toRem(4);
        }
    }
}

.epx-table .p-datatable-thead {
    background-color: clr(primary, light);
    border-radius: 4px;
}

.epx-table .p-datatable-thead tr > th {
    padding: 1rem;
    background-color: clr(primary, light);
    font-size: toRem(14);
    font-weight: $font-weight-medium;
    line-height: toRem(15);
    border: none;
    height: toRem(50);
}
