@import 'scss/core/variables';
@import 'scss/core/mixins';

.epx-color-white {
    color: white;
}

// Function to create class names from nested color maps
@each $palette-name, $color-map in $palette {
    @each $shade, $color in $color-map {
        .epx-color-#{'#{$palette-name}'}-#{$shade} {
            color: $color;
        }
    }
}

.content-stretched {
    display: flex;
    justify-content: stretch;
    flex-flow: column;
    height: 100%;

    & .content-row {
        flex: 1;
    }
    & .content-row:last-of-type {
        flex: unset;
        min-height: toRem(50);
    }
}

span.hint {
    padding: toRem(10) 0;
    color: clr(grey, 700);
    font-size: toRem(12);
    font-weight: $font-weight-regular;
    display: block;

    & > span {
    }
}

div.epx-divider:not(.p-divider) {
    height: toRem(2);
    background-color: $border-color;
    width: 100%;
}
