@import 'scss/core/variables';
@import 'scss/core/mixins';

.epx-split-button {
    & .p-tieredmenu {
        &.p-tieredmenu-overlay {
            width: 100%;
        }

        & .p-menuitem {
            & .p-menuitem-link {
                & .p-menuitem-text {
                    color: clr(primary, main);
                }

                &:hover:not(:disabled) {
                    background-color: clr(primary, light);
                }

                &:focus:not(:disabled) {
                    background-color: clr(primary, light);
                    box-shadow: none;
                    outline: none;
                }
            }
        }

        & .p-menuitem:not(:disabled).p-menuitem-active {
            & > .p-menuitem-link {
                background-color: clr(primary, light);
            }
        }
    }

    & .p-button {
        position: relative;
        padding: toRem(11) 1rem;
        height: 2.5rem;
        background-color: $palette-secondary-main;
        color: white;
        transition: all ease-in-out 0.3s;

        &:enabled:hover {
            background-color: $palette-secondary-dark;
            color: #ffffff;
            transition: all ease-in-out 0.3s;
        }

        &:enabled:focus {
            background-color: $palette-secondary-dark;
            box-shadow: none;
            color: #ffffff;
            transition: all ease-in-out 0.3s;
        }

        &:enabled:active {
            background-color: $palette-secondary-main;
            color: white;
        }

        &:disabled {
            background-color: $palette-secondary-light !important;
            color: white;
        }

        & .p-button-label {
            font-size: toRem(14);
            font-weight: $font-weight-regular;
            letter-spacing: toRem(1);
            text-align: start;
        }

        &.p-splitbutton-defaultbutton {
            border-top-left-radius: toRem(4);
            border-bottom-left-radius: toRem(4);
            border-right-color: transparent !important;
        }
    }

    &.epx-button-secondary {
        & .p-tieredmenu {
            & .p-menuitem {
                & .p-menuitem-link {
                    & .p-menuitem-text {
                        color: clr(primary, main);
                    }

                    &:hover:not(:disabled) {
                        background-color: clr(primary, light);
                    }

                    &:focus:not(:disabled) {
                        background-color: clr(primary, light);
                        box-shadow: none;
                        outline: none;
                    }
                }
            }

            & .p-menuitem:not(:disabled).p-menuitem-active {
                & > .p-menuitem-link {
                    background-color: clr(primary, light);
                }
            }
        }
        & .p-button {
            border-width: 1px;
            border-style: solid;
            border-color: clr(grey, 500);
            background-color: white;
            color: $palette-primary-main;

            &:enabled:hover {
                background-color: white !important;
                border-color: clr(grey, 300);
                color: $palette-primary-main;
            }

            &:enabled:focus {
                background-color: white !important;
                border-color: clr(grey, 300);
                color: $palette-primary-main;
            }

            &:enabled:active {
                background-color: white !important;
                border-color: clr(grey, 300);
                color: $palette-primary-main;
            }

            &:disabled {
                background-color: white !important;
                border-color: clr(grey, 500);
                color: $palette-primary-main;
            }
        }
    }
}
