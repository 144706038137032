@import 'scss/core/variables';
@import 'scss/core/mixins';

.epx-button.p-button {
    border-radius: toRem(4);
    position: relative;
    border: 1px solid transparent;
    padding: toRem(10) toRem(16);
    font-weight: $font-weight-medium;

    & span:not(.p-button-icon) {
        flex: 1 1 auto;
        font-weight: inherit;
        font-size: toRem(14);
        line-height: toRem(20);
        letter-spacing: toRem(1);
    }

    &:has(.p-button-icon) {
        justify-content: center;

        span:not(.p-button-icon) {
            flex: unset;
        }
    }

    &.p-button-icon-only {
        width: toRem(32);
        aspect-ratio: 1/1;
        padding: toRem(4);
        border-radius: 50%;

        & .p-button-icon {
            width: 100%;
            height: 100%;
        }

        & .epx-svg-icon {
            width: 100% !important;
            height: 100% !important;
        }

        &.epx-button-squared {
            border-radius: 0.5rem;
        }
    }

    &.epx-button-md {
        padding: toRem(6) toRem(12);

        & span:not(.p-button-icon) {
            flex: 1 1 auto;
            font-weight: inherit;
            font-size: toRem(10);
            line-height: toRem(16);
            letter-spacing: toRem(1);
            flex: unset;
        }

        &:has(.p-button-icon) {
            justify-content: center;
            line-height: toRem(16);
            span:not(.p-button-icon) {
                flex: unset;
            }
        }
    }

    &:not(:has(.p-button-label)) {
        border-radius: 50%;
        padding: toRem(5);
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: unset;
    }
}

.epx-button.p-button {
    background-color: $palette-secondary-main;
    color: white;
    transition: all ease-in-out 0.3s;

    &:enabled:hover {
        background-color: $palette-secondary-dark;
        color: white;
        transition: all ease-in-out 0.3s;
    }

    &:enabled:focus {
        background-color: $palette-secondary-dark;
        box-shadow: none;
        color: white;
        transition: all ease-in-out 0.3s;
    }

    &:enabled:active {
        background-color: $palette-secondary-main;
        color: white;
    }

    &:disabled {
        background-color: $palette-secondary-light !important;
        color: white !important;
    }
}

.epx-button.epx-button-secondary.p-button {
    border-color: clr(grey, 500);
    background-color: white;
    color: $palette-primary-main;

    &:enabled:hover {
        background-color: white !important;
        border-color: clr(grey, 300);
        color: $palette-primary-main;
    }

    &:enabled:focus {
        background-color: white !important;
        border-color: clr(grey, 300);
        color: $palette-primary-main;
    }

    &:enabled:active {
        background-color: white !important;
        border-color: clr(grey, 300);
        color: $palette-primary-main;
    }

    &:disabled {
        opacity: 0.3;
        background-color: white !important;
        border-color: clr(grey, 500);
        color: $palette-primary-main !important;
    }
}

.epx-button.epx-button-success.p-button {
    border-color: transparent;
    background-color: clr(success, light);
    color: clr(success, main);

    &:enabled:hover {
        border-color: transparent;
        background-color: clr(success, light);
        color: clr(success, main);
    }

    &:enabled:focus {
        border-color: transparent;
        background-color: clr(success, light);
        color: clr(success, main);
    }

    &:enabled:active {
        border-color: transparent;
        background-color: clr(success, light);
        color: clr(success, main);
    }

    &:disabled {
        opacity: 0.3;
        border-color: transparent;
        background-color: clr(success, light);
        color: clr(success, main);
    }
}

.epx-button.epx-button-error.p-button {
    border-color: transparent;
    background-color: clr(error, light);
    color: clr(error, main);

    &:enabled:hover {
        border-color: transparent;
        background-color: clr(error, light);
        color: clr(error, main);
    }

    &:enabled:focus {
        border-color: transparent;
        background-color: clr(error, light);
        color: clr(error, main);
    }

    &:enabled:active {
        border-color: transparent;
        background-color: clr(error, light);
        color: clr(error, main);
    }

    &:disabled {
        opacity: 0.3;
        border-color: transparent;
        background-color: clr(error, light);
        color: clr(error, main);
    }
}

.epx-button.epx-button-inactive.p-button {
    border-color: transparent;
    background-color: clr(primary, light);
    color: clr(grey, 800);

    &:enabled:hover {
        border-color: transparent;
        background-color: clr(primary, light);
        color: clr(grey, 800);
    }

    &:enabled:focus {
        border-color: transparent;
        background-color: clr(primary, light);
        color: clr(grey, 800);
    }

    &:enabled:active {
        border-color: transparent;
        background-color: clr(primary, light);
        color: clr(grey, 800);
    }

    &:disabled {
        opacity: 0.3;
        border-color: transparent;
        background-color: clr(primary, light);
        color: clr(grey, 800);
    }
}

.epx-button.epx-button-text.p-button {
    background: transparent;
    border: toRem(1) solid transparent;
    color: $palette-primary-main;
    transition: color 0.3s ease-in-out;

    &:enabled:hover {
        color: $palette-primary-dark;
        background-color: transparent !important;
        transform: scale(1.1);
        transition: transform ease-in-out 0.3s;
    }

    &:enabled:focus {
        color: $palette-secondary-main;
        background-color: transparent !important;
    }

    &:enabled:active {
        color: $palette-secondary-main;
        background-color: transparent !important;
    }

    &:disabled {
        color: clr(grey, 500) !important;
        background-color: transparent !important;
    }
}

.epx-toggle-button {
    // Basic Styling
    &.p-togglebutton.p-button {
        @include borderRadiusPrimary();
        height: toRem(28);

        .p-button-icon {
            font-size: 1.6rem;
            line-height: 1em;
        }

        .p-button-label {
            font-size: 1.6rem;
            line-height: 1em;
        }
    }

    &.small {
        & .p-togglebutton.p-button {
            width: 1.6rem;
            height: 1.6rem;

            .p-button-icon {
                font-size: 1rem;
                line-height: 1em;
            }
        }
    }
}

a.epx-ext-link-button {
    border-radius: toRem(4);
    position: relative;
    border: 1px solid transparent;
    padding: toRem(10) toRem(16);
    font-weight: $font-weight-medium;
    background-color: $palette-secondary-main;
    color: white !important;
    transition: all ease-in-out 0.3s;

    &:hover {
        background-color: $palette-secondary-dark;
        color: white !important;
        transition: all ease-in-out 0.3s;
    }

    &:focus {
        background-color: $palette-secondary-dark;
        box-shadow: none;
        color: white !important;
        transition: all ease-in-out 0.3s;
    }

    &:active {
        background-color: $palette-secondary-main;
        color: white !important;
    }

    &:disabled {
        background-color: $palette-secondary-light !important;
        color: white !important;
    }
}
