@use 'mixins';
@use 'variables';

.epx-radio.p-radiobutton {
    width: mixins.toRem(16);
    height: mixins.toRem(16);

    &:not(.p-radiobutton-disabled):hover {
        //border-color: variables.clr(grey, 500);
        box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.04);
    }

    & .p-radiobutton-box {
        border: 1px solid variables.clr(grey, 500);
        background-color: white;
        width: 100%;
        height: 100%;
        color: #3f3f46;
        border-radius: 50%;

        &:not(.p-radiobutton-disabled).p-focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
            background-color: white;
            border-color: variables.clr(grey, 500);
        }
    }

    &.p-radiobutton-checked {
        &:not(.p-radiobutton-disabled) {
            &.p-radiobutton-focused {
                box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.04);
                transition: none !important;
            }

            & .p-highlight {
                background-color: white;
                border-color: variables.clr(grey, 500);

                & .p-radiobutton-icon {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: white;

                    &:before {
                        font-family: primeicons;
                        content: '\e909';
                        color: variables.clr(success, main);
                        font-size: 11px;
                        font-weight: 800;
                        speak: none;
                        font-style: normal;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1;
                        display: inline-block;
                        -webkit-font-smoothing: antialiased;
                        //background-color: white;
                    }
                }
            }
        }
    }
}
