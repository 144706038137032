@import 'variables';
@import 'mixins';

page-dashboard,
page-employee-list,
page-customer-details,
page-employee-detail,
page-benefit-details,
app-ui-demo,
page-subsidy-list,
page-subsidy-detail,
app-module-router-layout,
page-customer-profile,
page-user-profile,
page-exports,
page-benefit-list,
page-exports-overview,
page-customer-settings,
page-exports-layout {
    flex: 1;
}

._module-content {
    padding: toRem(30);
}
