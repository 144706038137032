@use 'sass:math';
@import 'variables';
// TODO Setup Screen Sizes
@mixin for-size($size) {
    @if $size == xs {
        @media only screen and (max-width: 767px) {
            @content;
        }
    } @else if $size == sm {
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    } @else if $size == md {
        @media only screen and (min-width: 1024px) and (max-width: 1440px) {
            @content;
        }
    } @else if $size == lg {
        @media only screen and (min-width: 1440px) and (max-width: 1800px) {
            @content;
        }
    } @else if $size == xl {
        @media only screen and (min-width: 1800px) and (max-width: 2400px) {
            @content;
        }
    } @else if $size == xxl {
        @media only screen and (min-width: 2400px) {
            @content;
        }
    }
}

// Text Styles

@mixin text-base() {
    font-size: 1.6rem;
    line-height: 1.5rem;
    letter-spacing: normal;
    font-weight: normal;
}

@mixin text-secondary() {
    font-size: 1.25rem;
    line-height: 1.5;
}

@mixin text-tertiary() {
    font-size: 1rem;
    line-height: 1.5;
    color: $palette-primary-light;
}

// rem wit px fallback
// usage
// .main{
//    @inlcude rem("margin", 10, 0);
//    @inlcude rem("padding", 10);
//    @inlcude rem("font-size", 12);
// }

@mixin rem($property, $values...) {
    $n: length($values);
    $i: 1;

    $pxlist: ();
    $remlist: ();

    @while $i <= $n {
        $itemVal: (nth($values, $i));
        @if $itemVal != 'auto' {
            $pxlist: append($pxlist, $itemVal + px);
            $remlist: append($remlist, ($itemVal / 10) + rem);
        } @else {
            $pxlist: append($pxlist, auto);
            $remlist: append($remlist, auto);
        }

        $i: $i + 1;
    }

    #{$property}: $pxlist;
    #{$property}: $remlist;
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin bgOpacity($color: $palette-accent-grey, $opacity: 0.5) {
    background: rgba($color, $opacity) !important;
}

//Cross browser CSS3 mixins
@mixin box-shadow($offset-x, $offset-y, $blur-radius, $spread, $color) {
    box-shadow: toRem($offset-x) toRem($offset-y) toRem($blur-radius) toRem($spread) $color;
    -webkit-box-shadow: toRem($offset-x) toRem($offset-y) toRem($blur-radius) toRem($spread) $color;
    -moz-box-shadow: toRem($offset-x) toRem($offset-y) toRem($blur-radius) toRem($spread) $color;
}

@mixin border-radius($radius) {
    border-radius: toRem($radius);
    -webkit-border-radius: toRem($radius);
    -moz-border-radius: toRem($radius);
}

// Placeholders

@mixin placeholder-opacity($opacity) {
    &::-webkit-input-placeholder {
        /* WebKit browsers */
        opacity: $opacity;
    }
    &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        opacity: $opacity;
    }
    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        opacity: $opacity;
    }
    &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        opacity: $opacity;
    }
}

@mixin placeholder-color($color) {
    &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: $color;
    }
    &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: $color;
    }
    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: $color;
    }
    &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: $color;
    }
}

@mixin placeholder-font($font-family, $font-size, $font-weight) {
    &::-webkit-input-placeholder {
        /* WebKit browsers */
        font-weight: $font-weight;
        font-size: $font-size;
        font-family: $font-family;
    }
    &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        font-weight: $font-weight;
        font-size: $font-size;
        font-family: $font-family;
    }
    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        font-weight: $font-weight;
        font-size: $font-size;
        font-family: $font-family;
    }
    &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        font-weight: $font-weight;
        font-size: $font-size;
        font-family: $font-family;
    }
}

@mixin scrollbars($color: clr(grey, 600), $opacity: 0.5, $width: toRem(8)) {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
        width: $width;
        border-radius: 0.13rem;
        //background-clip: padding-box;
        //border: 1rem solid transparent;
        padding-left: 2rem;
    }

    &::-webkit-scrollbar-thumb {
        background: $color;
        opacity: $opacity;
    }
}

@mixin scrollContentContainer($height: 100%, $maxHeight: 85vh, $padding: 5rem) {
    height: $height;
    max-height: $maxHeight;
    padding-bottom: $padding;
    scrollbar-gutter: stable both-edges;
    overflow: hidden;
    @include scrollbars($width: toRem(4));
    @include scrollContainerHover();
}

@mixin scrollContainerHover() {
    &:hover {
        overflow-y: auto !important;
        transition: all 0.5s ease-in-out;
    }
}

@mixin text-overflow-ellipsis {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

@mixin transition($easing: ease-in-out, $timing: 0.2s, $properties...) {
    transition: all $easing $timing;
    -webkit-transition: all $easing $timing;
    -moz-transition: all $easing $timing;
    -o-transition: all $easing $timing;
    transition-property: $properties;
}

@function toRem($value) {
    $remValue: math.div($value, 16) + rem;
    @return $remValue;
}

@mixin borderRadiusPrimary() {
    @include border-radius(4);
}

@mixin boxShadowPrimary() {
    @include box-shadow(0, 2, 4, 0, clr(grey, 500));
}

// Button
@mixin button-base() {
    border-radius: toRem(4);
    position: relative;
    height: toRem(42);
    border: 1px solid transparent;
    padding: toRem(10) toRem(13);
    font-weight: $font-weight-medium;

    & .p-button-label {
        font-weight: inherit;
        font-size: toRem(14);
        letter-spacing: toRem(1);
    }
}

@mixin button-primary() {
    background-color: $palette-secondary-main;
    color: white;
    transition: all ease-in-out 0.3s;

    &:enabled:hover {
        background-color: $palette-secondary-dark !important;
        color: white;
        transition: all ease-in-out 0.3s;
    }

    &:enabled:focus {
        background-color: $palette-secondary-dark;
        box-shadow: none;
        color: white;
        transition: all ease-in-out 0.3s;
    }

    &:enabled:active {
        background-color: $palette-secondary-main;
        color: white;
    }

    &:disabled {
        background-color: $palette-secondary-light !important;
        color: white !important;
    }
}

@mixin button-secondary() {
    border-width: 1px;
    border-style: solid;
    border-color: clr(grey, 500);
    background-color: white;
    color: $palette-primary-main;

    &:enabled:hover {
        background-color: white;
        border-color: clr(grey, 300);
        color: $palette-primary-main;
    }

    &:enabled:focus {
        background-color: white;
        border-color: clr(grey, 300);
        color: $palette-primary-main;
    }

    &:enabled:active {
        background-color: white;
        border-color: clr(grey, 300);
        color: $palette-primary-main;
    }

    &:disabled {
        opacity: 0.3;
        background-color: white;
        border-color: clr(grey, 500);
        color: $palette-primary-main;
    }
}

@mixin epxPaperCard {
    background: white;
    @include borderRadiusPrimary();
    @include boxShadowPrimary();
    padding: toRem(30);
}
