@use '../shared/mixins';
@use '../shared/variables';

:root {
    --border-radius: mixins.toRem(4);
}

::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
    width: mixins.toRem(5);
    border-radius: 0.13rem;
    //background-clip: padding-box;
    //border: 1rem solid transparent;
    padding-left: 2rem;
}

::-webkit-scrollbar-thumb {
    background: variables.clr(grey, 600);
    opacity: 0.5;
}

html {
    font-size: 1rem;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: variables.$font-family-primary !important;
    font-weight: variables.$font-weight-regular;
    font-size: variables.$font-size-body;
    font-style: normal;
    line-height: 1.3em;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: variables.$palette-primary-main;
}

html,
body {
    background: #f2f1f1;
    height: 100%;
}

.p-component {
    font-family: variables.$font-family-primary;
    font-weight: variables.$font-weight-regular;
    font-size: 16px;
}

* {
    box-sizing: border-box;
}

p {
    margin: 0.5em auto;
}

:focus {
    outline: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
