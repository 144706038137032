@use 'mixins';
@use 'variables';

section._tabbed-navigation {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    gap: 1rem;
}

section._tabbed-navigation-nav {
    height: mixins.toRem(40);
}
section._tabbed-navigation-content {
    flex: 1;
    padding-top: mixins.toRem(20);
    padding-bottom: mixins.toRem(20);
}

.epx-tabs {
    color: variables.$palette-primary-main !important;
    &.p-tabs {
        width: max-content;
    }

    & .p-tablist-tab-list {
        background: transparent;
        border: mixins.toRem(1) solid rgba(variables.clr(grey, 600), 0.3);
        border-radius: mixins.toRem(8);
        overflow: hidden;
        width: max-content;
    }
    & .epx-tab-item.p-tab {
        padding: mixins.toRem(12);
        border-radius: mixins.toRem(8);

        &.p-tab-active.active {
            background-color: variables.$palette-primary-main !important;
            color: white;
        }

        &.p-tab:not(.p-tab-active):not(.p-disabled):hover {
            background-color: variables.$palette-primary-light !important;
        }
    }

    & .p-tablist-active-bar {
        display: none;
        visibility: hidden;
    }
}
