@use 'mixins';
@use 'variables';

._epx-paper-card {
    padding: 2.5rem;

    &._no-padding {
        padding: 0;
    }

    &._background {
        background: white;
        @include mixins.box-shadow(0, 2px, 4px, 0, variables.clr(grey, 600));
        @include mixins.border-radius(4);
    }
}
