@use 'mixins';
@use 'variables';

p-dropdown {
    width: 100%;
}

button.p-autocomplete-dropdown {
    background-color: variables.clr(primary, main);
    color: white;
}

.epx-dropdown-panel {
    font-weight: variables.$font-weight-medium;

    &.p-select-overlay {
        width: 100%;

        & > .p-select-list-container {
            background-color: white;
            max-height: calc(48px * 4) !important;
        }

        & .p-select-list .p-select-option {
            color: variables.$palette-primary-main;
            padding: mixins.toRem(12) mixins.toRem(16);
            font-family: variables.$font-family-primary;
            font-weight: variables.$font-weight-medium;
            font-style: normal;
            font-size: mixins.toRem(14);
            line-height: mixins.toRem(20);

            &:hover {
                background: variables.clr(primary, light) !important;
                transition: all ease-in-out 100ms;
            }

            &.p-select-option-selected {
                background: variables.clr(primary, light) !important;
            }

            & .p-ink {
                background-color: variables.clr(grey, 200);
            }
        }
    }
}

.epx-dropdown {
    width: 100%;

    &.p-dropdown {
        background-color: white;
        @include mixins.border-radius(0.5rem);
        border: 1px solid variables.clr(input, border);
        height: mixins.toRem(42);
        width: 100%;

        & .p-dropdown-trigger {
            height: 100%;
        }

        & .p-dropdown-filter-container {
            input {
                height: mixins.toRem(40);
            }
        }

        & .p-dropdown-label {
            color: variables.$palette-primary-main;
            font-family: variables.$font-family-primary;
            font-weight: variables.$font-weight-regular;
            font-style: normal;
            font-size: mixins.toRem(13);
            line-height: mixins.toRem(16);
            padding: mixins.toRem(12) mixins.toRem(16);
            width: 100%;
            height: 100%;
        }

        &:not(.p-disabled):hover {
            border-color: variables.clr(input, border-focus);

            &.p-focus {
                border-color: variables.$palette-primary-main;
            }
        }

        &:not(.p-disabled).p-focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
            border-color: variables.$palette-primary-main;

            & .p-dropdown-trigger {
                color: variables.$palette-primary-main;
            }
        }
    }
}
