@use 'mixins';
@use 'variables';

.epx-tag.p-tag {
    background-color: variables.clr(primary, light);
    color: variables.clr(grey, 800);
    padding: mixins.toRem(4) mixins.toRem(16);

    & .epx-tag-label {
        font-weight: variables.$font-weight-medium;
        font-size: mixins.toRem(10);
    }
}
