/* Fonts */
$font-family-primary: 'Poppins', sans-serif;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$font-size-body: 1rem;
$line-height-base: 1rem;

$palette-primary-main: rgba(50, 50, 50, 1);
$palette-primary-light: rgba(242, 241, 241, 1);
$palette-primary-dark: rgba(34, 34, 34, 1);

$palette-secondary-main: rgba(254, 112, 98, 1);
$palette-secondary-light: rgba(254, 198, 192, 1);
$palette-secondary-dark: rgba(224, 74, 72, 1);

$big-box-shadow: 0 0 1rem 0.9rem rgba($palette-primary-main, 0.1);
$small-box-shadow: 0 0 0.4rem 0.2rem rgba($palette-primary-main, 0.11);
$border-color: clr(primary, light);

$palette: (
    primary: (
        main: rgba(50, 50, 50, 1),
        light: rgba(242, 241, 241, 1),
        dark: rgba(34, 34, 34, 1)
    ),
    secondary: (
        main: rgba(254, 112, 98, 1),
        light: rgba(254, 198, 192, 1),
        dark: rgba(224, 74, 72, 1)
    ),
    success: (
        main: rgba(0, 200, 0, 1),
        light: rgba(221, 246, 224, 1)
    ),
    error: (
        main: rgba(255, 51, 84, 1),
        light: rgba(255, 204, 212, 1)
    ),
    warn: (
        main: rgba(255, 187, 0, 1),
        light: rgba(254, 249, 233, 1)
    ),
    info: (
        main: rgba(166, 99, 202, 1)
    ),
    help: (
        main: rgba(221, 221, 221, 1)
    ),
    input: (
        border: rgba(221, 221, 221, 1),
        border-focus: rgba(50, 50, 50, 1)
    ),
    grey: (
        800: rgba(106, 106, 106, 1),
        700: rgba(139, 139, 139, 1),
        600: rgba(184, 184, 184, 1),
        500: rgba(221, 221, 221, 1),
        300: rgba(243, 243, 243, 1),
        200: rgb(246, 246, 246, 1),
        100: rgba(250, 250, 250, 1)
    )
);

@function clr($base, $shade: main) {
    $color: map-get($palette, $base, $shade);
    @return $color;
}
