@import 'scss/core/variables';
@import 'scss/core/mixins';

.epx-accordion {
    &.p-accordion {
    }
}

.epx-accordion-tab.p-accordion-tab {
    box-shadow: none;
    border-bottom: 1px solid clr(grey, 500);

    & .p-accordion-content {
        padding: toRem(16) toRem(10);
    }

    & .p-accordion-header {
        & > .p-accordion-header-link {
            padding: toRem(16) toRem(10);
            font-size: toRem(16);
            font-weight: $font-weight-bold;
        }
    }
}
