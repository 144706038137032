@import 'scss/core/variables';
@import 'scss/core/mixins';

.epx-tag.p-tag {
    background-color: clr(primary, light);
    color: clr(grey, 800);
    padding: toRem(4) toRem(16);

    & .epx-tag-label {
        font-weight: $font-weight-medium;
        font-size: toRem(10);
    }
}
