@import 'scss/core/variables';
@import 'scss/core/mixins';

h3 {
    margin: 0;
    font-size: toRem(18);
    font-weight: 700;
    font-family: $font-family-primary;
    line-height: toRem(30);
    color: clr(primary, main);
    letter-spacing: 0;
}

h4 {
    margin: 0;
    font-size: toRem(12);
    font-weight: 700;
    font-family: $font-family-primary;
    line-height: toRem(20);
    color: clr(primary, main);
    letter-spacing: 0;
}

h5 {
    margin: 0;
    font-size: toRem(16);
    font-weight: 700;
    font-family: $font-family-primary;
    line-height: toRem(20);
    color: clr(primary, main);
    letter-spacing: 0;
}

a {
    color: $palette-primary-main;
    line-height: 0.938rem;
    font-size: toRem(12);
    text-decoration: none;
    font-weight: $font-weight-regular;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    gap: toRem(5);
    transition: color ease-in-out 0.3s;

    &.link-secondary {
        color: clr(secondary, main);
        font-size: toRem(14);

        &:hover {
            cursor: pointer;
            color: clr(primary, main);
            transition: color ease-in-out 0.3s;
        }
    }

    &:hover {
        cursor: pointer;
        color: clr(secondary, main);
        transition: color ease-in-out 0.3s;
    }
}

.data-box {
    font-style: normal;
    line-height: normal;

    & > label.label {
        color: clr(grey, 800);
        font-weight: $font-weight-regular;
        font-size: toRem(12);
    }

    & > label.value {
        color: clr(primary, main);
        font-weight: $font-weight-semi-bold;
        font-size: toRem(20);
    }
}
