@use 'mixins';
@use 'variables';

label.p-checkbox-label {
    font-size: mixins.toRem(12);
    font-style: normal;
    font-weight: variables.$font-weight-medium;
    margin-bottom: 0;
    line-height: mixins.toRem(18);
    margin-left: mixins.toRem(12);

    & span {
    }
}

.epx-checkbox {
    & .p-checkbox-box {
        border: 0.5px solid variables.clr(grey, 500);
    }
    &.p-checkbox-checked .p-checkbox-box:before {
        // Checkbox Icon Color
        // border-color: variables.clr(info, main) !important;
    }
}

.p-checkbox-box {
    background: transparent !important;
}

.p-checkbox {
    &.p-checkbox-checked {
        & .p-checkbox-box {
            border-color: variables.clr(primary, main) !important;
        }
    }

    &.p-checkbox-checked:not(.p-disabled):has(.p-checkbox-input:focus-visible) {
        box-shadow: none !important;
    }

    &:not(.p-disabled):has(.p-checkbox-input:hover) {
        box-shadow: none !important;
    }
}

/*.epx-checkbox {
    &.p-checkbox-checked.p-checkbox-box {
        background: white;
        box-shadow: none !important;
        outline: none !important;
    }

    .p-checkbox-checked:not(.p-disabled):has(.p-checkbox-input:focus-visible) {
        box-shadow: none !important;
    }

    &.p-checkbox-checked:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
        background: transparent;
        border-color: variables.clr(grey, 500);
        color: variables.clr(success, main);
    }

    &:not(.p-disabled):has(.p-checkbox-input:hover) {
        box-shadow: none !important;
    }

}

.epx-checkbox.p-checkbox.p-checkbox-checked:not(.p-checkbox-disabled).p-checkbox-focused {
    box-shadow: none;
}*/
