@use 'mixins';
@use 'variables';

.epx-tooltip {
    max-width: mixins.toRem(190);
    margin-left: mixins.toRem(5);

    &.p-tooltip-top .p-tooltip-arrow,
    .p-tooltip-bottom .p-tooltip-arrow {
        border-top-color: variables.clr(primary, main) !important;
    }

    & .p-tooltip-arrow {
        display: block !important;
        border-right-color: variables.clr(primary, main) !important;
    }

    &.p-tooltip {
        line-height: 1;
        display: inline-block !important;
    }

    & .p-tooltip-text {
        padding: mixins.toRem(12) mixins.toRem(12);
        font-size: mixins.toRem(10);
        font-style: normal;
        font-family: variables.$font-family-primary;
        font-weight: variables.$font-weight-regular;
        background: variables.clr(primary, main);
        color: white;
    }
}
