@use 'mixins';
@use 'variables';

.epx-table {
    & .p-datatable-header {
        padding: 0 0 mixins.toRem(10) 0;
        border: none;
    }

    & .p-datatable-table {
        border-collapse: separate;
        border-spacing: 0 mixins.toRem(6) !important;
    }
    & .p-datatable-wrapper {
        scrollbar-gutter: stable;
        overflow: hidden !important;
        padding-right: 0.5rem;
        @include mixins.scrollContainerHover();
    }

    & .p-sortable-column.p-highlight {
        background-color: variables.clr(primary, light);
        color: inherit;

        &:focus {
            background-color: variables.clr(primary, light);
        }
    }
}

.epx-table.p-datatable {
    & .p-datatable-sortable-column:not(.p-datatable-column-sorted):hover {
        background-color: variables.clr(grey, 500);
        cursor: pointer;
        transition: font-weight ease-out 0.1s;
        color: variables.clr(primary, dark);
    }

    .p-datatable-thead > tr > th.p-datatable-column-sorted {
        background-color: variables.clr(primary, light);
        color: variables.clr(primary, dark);
    }

    &.p-datatable-hoverable .p-datatable-tbody > tr:not(.p-datatable-row-selected):hover {
        background-color: variables.clr(primary, light);
        cursor: pointer;
        transition: font-weight ease-out 0.1s;
    }
}

.epx-table .p-datatable-tbody {
    & tr {
        font-weight: variables.$font-weight-regular;
        transition: font-weight ease-in 0.1s;
    }

    & tr > td {
        height: mixins.toRem(65);
        border-top: 1px solid variables.clr(primary, light);
        border-bottom: 1px solid variables.clr(primary, light);
        font-size: mixins.toRem(14);
        font-weight: inherit;
        font-family: variables.$font-family-primary;
        color: variables.clr(primary, main);
        line-height: 1.43;
        display: table-cell;
        vertical-align: inherit;
        padding: mixins.toRem(8) mixins.toRem(20);

        &:first-of-type {
            border-left: 1px solid variables.clr(primary, light);
            border-top-left-radius: mixins.toRem(4);
            border-bottom-left-radius: mixins.toRem(4);
        }
        &:last-of-type {
            border-right: 1px solid variables.clr(primary, light);
            border-top-right-radius: mixins.toRem(4);
            border-bottom-right-radius: mixins.toRem(4);
        }
    }
}

.epx-table .p-datatable-thead {
    background-color: variables.clr(primary, light);
    border-radius: 4px;
}

.epx-table .p-datatable-thead tr > th {
    padding: 1rem;
    background-color: variables.clr(primary, light);
    color: variables.clr(primary, main);
    font-size: mixins.toRem(14);
    font-weight: variables.$font-weight-medium;
    line-height: mixins.toRem(15);
    border: none;
    height: mixins.toRem(50);
}
