@use '../shared/mixins' as *;
@use '../shared/variables' as *;

.p-editor-container {
    & .p-editor-content.ql-snow {
        border-bottom: transparent;
    }

    & .p-editor-content .ql-editor {
    }
}
