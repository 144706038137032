@use 'mixins';
@use 'variables';

.epx-accordion {
    &.p-accordion {
    }
}

.epx-accordion-tab.p-accordion-tab {
    box-shadow: none;
    border-bottom: 1px solid variables.clr(grey, 500);

    & .p-accordionheader {
        &:hover {
        }
    }

    & .p-accordion-content {
        padding: mixins.toRem(16) mixins.toRem(10);
    }

    & .p-accordion-header {
        & > .p-accordion-header-link {
            padding: mixins.toRem(16) mixins.toRem(10);
            font-size: mixins.toRem(16);
            font-weight: variables.$font-weight-bold;
        }
    }
}
